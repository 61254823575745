import { createApp } from "vue";

import SmartSearch from "./SmartSearch.vue";

const handle = "almero-vue-smart-search";
const mountEls = document.querySelectorAll(`.${handle}`);

mountEls.forEach((mountEl) => {
    let props = {};
    if (mountEl.dataset.rootProps) {
        try {
            props = JSON.parse(mountEl.dataset.rootProps);
        }
        catch (e) {
            console.error(e);
        }
    }
    const app = createApp(
        SmartSearch, {
            ...props,
        }
    );

    app.component("SmartSearch", SmartSearch);

    app.mount(mountEl);
});