<template>
    <div class="smart-search">
        <button class="nav-button smart-search__open" @click="$refs.dialog.showModal()">
            <img :src="searchIcon" alt="open" />
        </button>
        <Teleport to="body">
        <dialog class="smart-search smart-search__dialog" ref="dialog">
            <button class="nav-button smart-search__close" @click="$refs.dialog.close()"><div class="close"></div></button>
            <div class="smart-search__container">
                <form class="smart-search__form" @submit.prevent="doSearch">
                    <input
                        class="smart-search__input"
                        type="search"
                        v-model="search"
                        placeholder="Search for a city, university or property"
                        :disabled="busy"
                        list="auto-smart"
                        />
                    <datalist id="auto-smart">
                        <option v-for="result in autoSmartResults" :value="result"></option>
                    </datalist>
                    <button
                        type="submit"
                        :disabled="busy"
                        class="smart-search__submit"
                        >            
                        <img :src="searchIcon" alt="open" />
                        <span>Search</span>
                    </button>
                </form>
            </div>
            <div v-if="results" class="smart-search__results smart-search-results">
                <div class="smart-search-results__column">
                    <h2 class="smart-search-results__title">City</h2>
                    <ul class="smart-search-results__list smart-search-results__list--locations">
                        <li v-for="city in results.locations" :key="city.name">
                            <img :src="locationIcon" />

                            <a :href="city.url">{{ city.name }}</a>
                        </li>
                        <li v-if="!results.locations.length">No cities found</li>
                    </ul>
                </div>
                <div class="smart-search-results__column">
                    <h2 class="smart-search-results__title">University</h2>
                    <ul class="smart-search-results__list smart-search-results__list--universities">
                        <li v-for="university in results.universities" :key="university.name">
                            <img :src="uniIcon" />

                            <a :href="university.url">{{ university.name }}</a>
                        </li>
                        <li v-if="!results.universities.length">No universities found</li>
                    </ul>
                </div>
                <div class="smart-search-results__column">
                    <h2 class="smart-search-results__title">Property</h2>
                    <ul class="smart-search-results__list smart-search-results__list--properties">
                        <li v-for="property in results.properties" :key="property.name">
                            <img :src="homeIcon" />
                            <a :href="property.url">{{ property.name }}</a>
                        </li>
                        <li v-if="!results.properties.length">No properties found</li>
                    </ul>
                </div>
            </div>
        </dialog>
        </Teleport>
    </div>
</template>
<script setup>
import { ref, onMounted, Teleport } from 'vue';
import axios from 'axios';
import { apiUrl } from '@utils/api.js';
import { themeDir } from '@utils/theme.js';

const search = ref('');
const busy = ref(false);
const results = ref(null);
const dialog = ref(null);

const searchIcon = `${themeDir}/images/icons/search.svg`;
const homeIcon = `${themeDir}/images/icons/home.svg`;
const locationIcon = `${themeDir}/images/icons/location.svg`;
const uniIcon = `${themeDir}/images/icons/uni.svg`;

const doSearch = async (noStateUpdate, noResults) => {
    if (busy.value) {
        return;
    }
    busy.value = true;
    const response = await axios.get(`${apiUrl}/search-smart`, {
        params: {
            search: search.value
        }
    });
    busy.value = false;
    if (!noResults) {
        results.value = response.data;
    }

    updateAutoSmartResults(response.data);

    if (!noStateUpdate) {
        window.history.pushState({}, '', '/?search=' + search.value);
    }
};

const autoSmartResults = ref([]);

const updateAutoSmartResults = (results) => {
    if (autoSmartResults.value.length) {
        return;
    }
    autoSmartResults.value = [
        ... new Set([
            ...results.locations?.map(location => location.name),
            ...results.universities?.map(university => university.name),
            ...results.properties?.map(property => property.name)
        ])
    ];
};

onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('search')) {
        search.value = urlParams.get('search');
    }
    doSearch(true, true);
});
</script>